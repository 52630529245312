<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('billingHistory') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :license="license"
    :request="request"
    :is-search="false"
    :csv-handler="downloadCsvHandler"
    :sort="'desc'"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table
      v-else
      :license="license"
      :list="list"
    ></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { store } from '@/application/store'
import { getHistory } from '@/application/services/billing'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formatterCSVBillingHistory } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'HistroyPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/HistoryTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'billing-history.csv',
      formatterCSVBillingHistory,
      {
        create_date: i18n.t('forms.billing.rows.date'),
        currency: i18n.t('forms.billing.rows.currency'),
        price: i18n.t('forms.billing.rows.price'),
        payment_tax: i18n.t('forms.billing.rows.tax'),
        gross_price: i18n.t('forms.billing.rows.totalPrice'),
        payment_method: i18n.t('forms.billing.rows.paymentMethod'),
        coupon: i18n.t('forms.billing.rows.coupon'),
      }
    )
    return {
      license,
      request: getHistory,
      downloadCsvHandler,
    }
  },
}
</script>
